import React from "react";
import { Text } from "../../global";
import "./styles.css";

export const Footer = () => {
  return (
    <div className="footer-main">
      {/* <div className="hr" /> */}
      <div className="madeby">
        <Text varient="content2">Made by Sharadindu Paul & Arpan Sadhu </Text>
      </div>
    </div>
  );
};
